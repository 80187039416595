import React, { ReactElement } from "react"
import { HeadingLine } from "./HeadingLine"
import Layout from "../components/layout"
import { Container, Box, Heading } from "../components/ui"
import { boxShadow } from "../basic/box-shadow.css"
import Head from "../components/head"

export interface IPageContainer {
  title: string
  description?: string
  image?: { id: string; url: string }
}

interface PageContainerProps {
  page: IPageContainer
  children: ReactElement | ReactElement[]
}

export const PageContainer = (props: PageContainerProps) => {
  const { page, children } = props

  return (
    <>
      <Head
        title={page.title}
        description={page.description}
        image={page.image}
      />
      <Container>
        <Box paddingY={3}>
          <Heading as="h1">{page.title}</Heading>
          <HeadingLine />
          <Box className={boxShadow} padding={1}>
            {children}
          </Box>
        </Box>
      </Container>
    </>
  )
}
