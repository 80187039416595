import { Script } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React, { useEffect, useState } from "react"
import { useRef } from "react"
import { PageContainer } from "../basic/PageContainer"

const page = {
  title: "Mannschaften",
  description: "Mannschaften des FC Ottenhöfen",
}

const API_KEYS = [
  "01T56CRJ6C000000VS541L4JVSUBKA2L", // TEAM 1
  "01T56DKQ5O000000VS541L4GVT9M1H51", // TEAM 2
  "01T56DQLTG000000VS541L4KVSO5UMPT", // TEAM 3
  "01T56EM230000000VS541L4KVVNO4Q6P", // A-JGD
  "01T56DUUEO000000VS541L4QVTSA3RAV", // B-JGD
  "01T56E2CCK000000VS541L4RVT0QB554", // C1-JGD
  "01T56E2CCK000000VS541L4RVT0QB554", // C2-JGD
  "02J4PJOG4G000000VUM1DNORVTH5RBQH", // D-JGD
  "01T56ED0EO000000VS541L4JVVB6UFD7", // E-JGD
]

const imageWidth = 400

const images = [
  <StaticImage src={"../images/TEAM_1.jpg"} height={imageWidth} alt="" />,
  <StaticImage src={"../images/TEAM_2.jpg"} height={imageWidth} alt="" />,
  <StaticImage src={"../images/TEAM_3.jpg"} height={imageWidth} alt="" />,
  <StaticImage src={"../images/A-Junioren.jpg"} height={imageWidth} alt="" />,
  <StaticImage src={"../images/B1-Junioren.jpg"} height={imageWidth} alt="" />,
  <StaticImage src={"../images/C1-Junioren.jpg"} height={imageWidth} alt="" />,
  <StaticImage src={"../images/C2-Junioren.jpg"} height={imageWidth} alt="" />,
  <StaticImage src={"../images/D-Junioren.jpg"} height={imageWidth} alt="" />,
  <StaticImage src={"../images/E-Junioren.jpg"} height={imageWidth} alt="" />,
  <StaticImage src={"../images/F-Junioren.jpg"} height={imageWidth} alt="" />,
  <StaticImage src={"../images/G-Junioren.jpg"} height={imageWidth} alt="" />,
]

export default function teams() {
  const fussballdeWidgetAPIinstance = useRef<any | undefined>()

  const [selectedTeamIndex, setSelectedTeamIndex] = useState(0)

  const handleSelect = () => {
    var index = (document.getElementById("teamselection") as HTMLSelectElement)
      .selectedIndex
    setSelectedTeamIndex(index)
  }

  const showTeam = () => {
    if (fussballdeWidgetAPIinstance.current) {
      var apiKey = API_KEYS[selectedTeamIndex]

      if (apiKey) {
        fussballdeWidgetAPIinstance.current.showWidget(
          "table_standings",
          API_KEYS[selectedTeamIndex]
        )
        document.getElementById("table_standings").style.display = "inherit"
      } else {
        document.getElementById("table_standings").style.display = "none"
      }
    }
  }

  const onLoad = () => {
    //@ts-ignore
    if (window.fussballdeWidgetAPI && !fussballdeWidgetAPIinstance.current) {
      //@ts-ignore
      fussballdeWidgetAPIinstance.current = new fussballdeWidgetAPI()
      showTeam()
    }
  }

  useEffect(() => {
    showTeam()
  }, [selectedTeamIndex])

  return (
    <PageContainer page={page}>
      {
        <Script
          id="fussballdeApi"
          src="https://www.fussball.de/static/layout/fbde2/egm//js/widget2.js"
          onLoad={onLoad}
        />
      }

      <div style={{ display: "flex", flexDirection: "column" }}>
        <select
          id="teamselection"
          style={{ width: "200px", margin: "10px", padding: "5px" }}
          onChange={handleSelect}
        >
          <optgroup label="Herren">
            <option>1. Mannschaft</option>
            <option>2. Mannschaft</option>
            <option>3. Mannschaft</option>
          </optgroup>
          <optgroup label="Jugend">
            <option>A-Junioren</option>
            <option>B-Junioren</option>
            <option>C1-Junioren</option>
            <option>C2-Junioren</option>
            <option>D-Junioren</option>
            <option>E-Junioren</option>
            <option>F-Junioren</option>
            <option>G-Junioren</option>
          </optgroup>
        </select>
        <div style={{ maxHeight: "400px" }}>{images[selectedTeamIndex]}</div>
        <div id="table_standings" />
      </div>
    </PageContainer>
  )
}
